export default defineNuxtRouteMiddleware(() => {
  const user = useSanctumUser<User>();
  const localePath = useLocalePath();

  const isAdmin = user.value?.roles.some((role: string) => {
    return ADMIN_ROLES.includes(role);
  });

  if (!isAdmin) {
    return navigateTo(localePath("/tools/summaries"));
  }
});
